<template>
  <div class="user_register">
    <QuizzTitle :width="219" :height="174" />
    <div class="input_container">
      <input
        type="text"
        v-model="user_name"
        placeholder="Entrez votre pseudo/prénom"
        maxlength="30"
      />
      <p class="error-message" v-if="user_name_taked">
        Ce prénom / pseudo est déjà pris, merci d'en utiliser un autre.
      </p>
    </div>
    <button type="button" @click="newUser">Participez !</button>
    <p class="instruction">
      *le critère de rapidité de réponse est pris en compte et départage les
      bonnes réponses.
    </p>
  </div>
</template>
<script>
import QuizzTitle from "../utils/QuizzTitle.vue";
export default {
  name: "UsersRegister",
  components: {
    QuizzTitle,
  },
  props: ["user_name_taked"],
  data() {
    return {
      user_taked: this.user_name_taked,
      user_name: "",
    };
  },
  methods: {
    newUser() {
      if (this.user_name != "") {
        this.$emit("new-user", this.user_name);
      }
    },
  },
  watch: {
    user_name_taked: {
      handler(newNameTaked) {
        this.user_taked = newNameTaked;
      },
    },
  },
};
</script>
<style scoped>
.user_register {
  margin-top: 154px;
}

.input_container {
  margin: 40px 5px 24px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
input {
  padding: 19px 23px;
  border: 0;
  background-color: #f5f5f5;
  color: #000000;
  /* font */
  font-family: "Quicksand";
  font-weight: 400;
  font-size: 0.875em;
  line-height: 100%;
}
input:focus,
input:focus-visible {
  outline: none;
}

.input_container .error-message {
  text-align: left;
  margin: 0;
  color: #e6b319;
  /*font */
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 0.75em;
  line-height: 120%;
}

button {
  width: 100%;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6b319;
  border-radius: 10px;
  border: 0;
  color: #fff;
  /*font */
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1em;
  line-height: 100%;
}

.instruction {
  margin: 23px 0 0 0;
  text-align: left;
  /*font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 120%;
}
</style>