<template>
  <div>
    <img
      class="quizz_title"
      src="@/assets/logo_jeu_PCC.svg"
      alt="logo Le grand quiz PCC"
      :width="width"
      :height="height"
    />
  </div>
</template>
<script>
export default {
  name: "QuizzTitle",
  props: {
    width: {
      type: [Number, String],
      default: 180,
    },
    height: {
      type: [Number, String],
      default: 143.31,
    },
  },
};
</script>