<template>
  <div
    v-if="
      question.question == '' &&
      question.answer == null &&
      question.explain == '' &&
      question.image == ''
    "
  >
    <QuizzTitle />
    <QuizzError @refreshGame="refreshGame" />
  </div>
  <div v-else>
    <div v-if="timer > 0" class="question_show">
      <QuizzTitle />
      <h4>
        {{ question.question }}
      </h4>
      <div class="timer_wrapper">
        <TimerCountdown :timer_prop="timer" />
      </div>
      <div class="answers">
        <div class="answer true">
          <img
            src="@/assets/icons/thumb.svg"
            alt="Icone de pouce en l'air"
            width="101.2"
            height="98.11"
          />
          Vrai
        </div>
        <div class="answer false">
          <img
            src="@/assets/icons/thumb.svg"
            alt="Icone de pouce en l'air"
            width="101.2"
            height="98.11"
          />
          Faux
        </div>
      </div>
    </div>
    <div v-else class="question_result">
      <h5>
        {{ question.question }}
      </h5>
      <div class="result_container">
        <div class="image_col">
          <img
            :src="'./question_images/' + question.image"
            alt="Icone pour la question"
            width="591"
            height="436"
            loading="lazy"
          />
        </div>
        <div class="content_col">
          <div :class="'answer_title ' + (question.answer ? 'true' : 'false')">
            <h3>{{ question.answer ? "Vrai" : "Faux" }} !</h3>
            <img
              src="@/assets/icons/thumb.svg"
              alt="Icone de pouce en l'air"
              width="49.98"
              height="48.46"
            />
          </div>
          <p v-html="question.explain"></p>
          <button type="button" @click="launchNextQuestion" v-if="!quizz_ended">
            Question suivante
            <img
              src="@/assets/icons/arrow_next.svg"
              alt="Icone flèche suivant"
              width="45"
              height="15"
            />
          </button>
          <button type="button" @click="showResults" v-else>
            Voir le classement final
            <img
              src="@/assets/icons/arrow_next.svg"
              alt="Icone flèche suivant"
              width="45"
              height="15"
            />
          </button>
          <div :class="'user_top ' + (question.answer ? 'true' : 'false')">
            <h6>Top 3</h6>
            <div class="users">
              <div v-for="(user, id) in users_list" :key="id" class="user">
                <img
                  v-if="user.icon_type == 'poisson'"
                  src="@/assets/icons/poisson.svg"
                  alt="Icone de poisson"
                  width="61.92"
                  height="30"
                />
                <img
                  v-else-if="user.icon_type == 'coquillage'"
                  src="@/assets/icons/coquillage.svg"
                  alt="Icone de coquillage"
                  width="57.22"
                  height="32"
                />
                <img
                  v-else-if="user.icon_type == 'pince'"
                  src="@/assets/icons/pince.svg"
                  alt="Icone de pince"
                  width="58.25"
                  height="32"
                />
                <span>{{ user.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuizzError from "./QuizzError.vue";
import QuizzTitle from "../utils/QuizzTitle.vue";
import TimerCountdown from "../utils/TimerCountdown.vue";
export default {
  name: "QuestionShow",
  components: {
    QuizzError,
    QuizzTitle,
    TimerCountdown,
  },
  props: [
    "question_prop",
    "timer_prop",
    "question_count_prop",
    "quizz_ended_props",
    "top_users",
  ],
  data() {
    return {
      timer: this.timer_prop,
      question_count: this.question_count_prop,
      question: this.question_prop,
      quizz_ended: this.quizz_ended_props,
      users_list: this.top_users,
    };
  },
  methods: {
    launchNextQuestion() {
      this.$emit("launchNextQuestion");
    },
    showResults() {
      this.$emit("showResults");
    },
    refreshGame() {
      this.$emit("refreshGame");
    },
  },
  watch: {
    question_prop: {
      handler(newQuestion) {
        this.question = newQuestion;
      },
      deep: true,
    },
    timer_prop: {
      handler(newTimer) {
        this.timer = newTimer;
      },
    },
    question_count_prop: {
      handler(newQuestionCount) {
        this.question_count = newQuestionCount;
      },
    },
    quizz_ended_props: {
      handler(newQuizzEnded) {
        this.quizz_ended = newQuizzEnded;
      },
    },
    top_users: {
      handler(newTop) {
        this.users_list = newTop;
      },
      deep: true,
    },
  },
};
</script> 
<style scoped>

.question_show {
  margin-top: 60px;
}
.question_show h4 {
  margin: 42px auto 30px;
  max-width: 1515px;
  text-align: center;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 3.4375em;
  line-height: 67px;
}
.question_show .timer_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 88px;
}
.question_show .answers {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 60px;
}
.question_show .answers .answer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  padding: 30px 50px 25px 50px;
  border-radius: 20px;
  box-shadow: 2px 6px 8px 1px rgba(0, 0, 0, 0.25);
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 3em;
  line-height: 60px;
}
.question_show .answers .answer.true {
  background-color: #9ec759;
}
.question_show .answers .answer.true img {
  transform: rotate(-20.04deg);
}
.question_show .answers .answer.false {
  background-color: #d60000;
}
.question_show .answers .answer.false img {
  transform: rotate(135deg);
}

.question_result {
  margin-top: 92px;
}
.question_result h5 {
  margin: 0 auto 92px;
  max-width: 900px;
  text-align: center;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  line-height: 45px;
}

.question_result .result_container {
  margin-top: 0;
  display: flex;
  justify-content: center;
  column-gap: 185px;
}
.question_result .result_container .image_col {
  width: 591px;
  text-align: start;
}
.question_result .result_container .image_col img {
  border-radius: 10px;
}
.question_result .result_container .content_col {
  width: 745px;
  text-align: start;
}
.question_result .content_col .answer_title {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  column-gap: 20px;
  margin-bottom: 35px;
}
.question_result .content_col .answer_title h3 {
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 10;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5625em;
  line-height: 71px;
}
.question_result .content_col .answer_title h3::after {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 7px;
  left: 0;
  right: 0;
  width: 100%;
  height: 14px;
  border-radius: 2px;
}
.question_result .content_col .answer_title.true h3::after {
  background-color: rgba(158, 199, 89, 0.92);
}
.question_result .content_col .answer_title.false h3::after {
  background-color: #d60000;
}
.question_result .content_col .answer_title.true img {
  transform: rotate(-20.04deg);
}
.question_result .content_col .answer_title.false img {
  transform: rotate(149.95deg);
}

.question_result .content_col p {
  margin-top: 0;
  margin-bottom: 40px;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 2.1875em;
  line-height: 40px;
}

.question_result .content_col button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 18px;
  cursor: pointer;
  padding: 14.5px 18px 14.5px 22px;
  border: none;
  color: #fff;
  background-color: #9ec759;
  border-radius: 16px;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 28px;
}

.question_result .content_col .user_top {
  margin-top: 90px;
}
.question_result .content_col .user_top h6 {
  margin-top: 0;
  margin-bottom: 30px;
  width: fit-content;
  text-transform: uppercase;
  position: relative;
  z-index: 10;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 1.375em;
  line-height: 24px;
}
.question_result .content_col .user_top h6::after {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  width: 100%;
  height: 7px;
  border-radius: 2px;
}
.question_result .content_col .user_top.true h6::after {
  background-color: rgba(158, 199, 89, 0.92);
}
.question_result .content_col .user_top.false h6::after {
  background-color: #d60000;
}

.question_result .content_col .user_top .users {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 50px;
}

.question_result .content_col .user_top .users .user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 25px;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125em;
  line-height: 24px;
}
</style>