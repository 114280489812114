<template>
  <footer>
    <div class="admin_footer_wrapper">
      <img
        class="logo_vague"
        src="@/assets/footer/logo_PCC_vague.svg"
        alt="logo Poissons, Coquillages et Crustacés"
        width="195.92"
        height="166.44"
      />
      <img
        class="text"
        src="@/assets/footer/text.svg"
        alt="Ils n'ont pas fini de vous surprendre."
        width="589"
        height="55"
      />
      <img
        class="list_logos"
        src="@/assets/footer/logos.png"
        alt="Logos Partenaires"
        width="356"
        height="80"
      />
    </div>
  </footer>
</template>
<script>
export default {
  name: "AdminFooter",
};
</script>
<style scoped>
footer {
  height: 80px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.admin_footer_wrapper {
  background-color: #fff;
  height: 80px;
  width: 100%;
  position: relative;
}
.admin_footer_wrapper .logo_vague {
    position: absolute;
    bottom: 14px;
    left: 63px;
}
.admin_footer_wrapper .text {
    position: absolute;
    top: 50%;
    left: 308px;
    transform: translateY(-50%);
}
.admin_footer_wrapper .list_logos {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
}
</style>