<template>
  <div class="waiting_screen">
    <QuizzTitle />
    <div class="waiting_screen_wrapper">
      <div class="qr_code_col">
        <h2>Scannez le code pour commencer à jouer</h2>
        <img
          src="@/assets/qr_code.png"
          alt="QR code"
          width="300"
          height="300"
        />
        <p>OU</p>
        <h4>{{ game_url }}</h4>
      </div>
      <div class="users_col">
        <h2>Inscrits :</h2>
        <div class="users">
          <div v-for="(user, id) in users" :key="id" class="user">
            <img
              v-if="user.icon_type == 'poisson'"
              src="@/assets/icons/poisson.svg"
              alt="Icone de poisson"
              width="61.92"
              height="30"
            />
            <img
              v-else-if="user.icon_type == 'coquillage'"
              src="@/assets/icons/coquillage.svg"
              alt="Icone de coquillage"
              width="57.22"
              height="32"
            />
            <img
              v-else-if="user.icon_type == 'pince'"
              src="@/assets/icons/pince.svg"
              alt="Icone de pince"
              width="58.25"
              height="32"
            />
            <span>{{ user.name }}</span>
          </div>
        </div>
        <button type="button" @click="startAGame" v-if="users.length > 0">
          C'est parti !
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import QuizzTitle from "../utils/QuizzTitle.vue";
export default {
  name: "WaitingScreen",
  components: {
    QuizzTitle,
  },
  props: ["user_list"],
  data() {
    return {
      users: this.user_list,
      game_url: process.env.VUE_APP_GAME_URL,
    };
  },
  methods: {
    startAGame() {
      this.$emit("startGame");
    },
  },
  watch: {
    user_list: {
      handler(newUsers) {
        this.users = newUsers;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.waiting_screen {
  z-index: 20;
  margin-top: 80px;
}
.waiting_screen_wrapper {
  margin-top: 112px;
  display: flex;
  justify-content: center;
  column-gap: 307px;
}
.waiting_screen_wrapper .qr_code_col {
  width: 316px;
  text-align: start;
}
.waiting_screen_wrapper .users_col {
  width: 785px;
  text-align: start;
}

h2 {
  margin-top: 0;
  max-width: 280px;
  height: 55px;
  text-transform: uppercase;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.1875em;
  line-height: 24px;
}
.waiting_screen_wrapper .qr_code_col h2 {
  margin-bottom: 20px;
}
.waiting_screen_wrapper .users_col h2 {
  margin-bottom: 5px;
}
.waiting_screen_wrapper .qr_code_col img {
  border-radius: 20px;
}
.waiting_screen_wrapper .qr_code_col p {
  margin: 5px 0 0 0;
  text-align: center;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 24px;
}
.waiting_screen_wrapper .qr_code_col h4 {
  margin: 5px auto 0 auto;
  text-align: center;
  max-width: 80%;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125em;
  line-height: 24px;
}

.users {
  padding-left: 25px;
  margin-bottom: 61px;
  height: 333.5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  row-gap: 40px;
  column-gap: 60px;
}

.users .user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 25px;
  width: 350px;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
}

button {
  cursor: pointer;
  padding: 14px 50px;
  border: none;
  color: #fff;
  background-color: #9ec759;
  border-radius: 16px;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 28px;
}
</style>