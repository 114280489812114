import { createWebHistory, createRouter } from "vue-router";
import UsersHome from "@/views/UsersHome.vue";
import AdminsPresent from "@/views/AdminsPresent.vue";

const routes = [{
        path: "/",
        name: "UsersHome",
        component: UsersHome,
    },
    {
        path: "/present",
        name: "AdminsPresent",
        component: AdminsPresent,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;