<template>
  <footer>
    <div class="user_footer_wrapper">
      <a
        href="https://poissons-coquillages-crustaces.fr/"
        target="_blank"
        class="logo_vague"
      >
        <img
          src="@/assets/footer/logo_PCC_vague.svg"
          alt="logo Poissons, Coquillages et Crustacés"
          width="85"
          height="70"
      /></a>
      <img
        class="list_logos"
        src="@/assets/footer/logos.png"
        alt="Logos Partenaires"
        width="326.63"
        height="77.21"
      />
    </div>
  </footer>
</template>
<script>
export default {
  name: "UsersFooter",
};
</script>
<style scoped>
footer {
  margin: 100px 0px 0px -30px;
  width: calc(100% + 60px);
  height: 91px;
  position: relative;
  z-index: 9999;
  background-color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.user_footer_wrapper {
  background-color: #fff;
  height: 91px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.user_footer_wrapper .logo_vague {
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translateX(-50%);
}
.user_footer_wrapper .list_logos {
  max-width: calc(100% - 60px);
  height: auto;
}
</style>