<template>
  <div v-if="timer > 0" class="question_show">
    <h4>
      {{ question.question }}
    </h4>
    <div class="timer_wrapper">
      <TimerCountdown
        :timer_prop="timer"
        :timer_width="50"
        :timer_height="50"
        :timer_stroke_width="4"
        :label_font_size="20"
        :label_line_height="24"
      />
    </div>
    <div class="answers">
      <button
        :class="
          'answer true ' +
          (user_answer != null && user_answer ? 'selected' : '')
        "
        @click="saveUserResponse(true)"
      >
        <img
          src="@/assets/icons/thumb.svg"
          alt="Icone de pouce en l'air"
          width="74"
          height="71"
        />
        Vrai
      </button>
      <button
        :class="
          'answer false ' +
          (user_answer != null && !user_answer ? 'selected' : '')
        "
        @click="saveUserResponse(false)"
      >
        <img
          src="@/assets/icons/thumb.svg"
          alt="Icone de pouce en l'air"
          width="74"
          height="71"
        />
        Faux
      </button>
    </div>
  </div>
  <div v-else class="question_result">
    <div :class="'answer_title ' + (question.answer ? 'true' : 'false')">
      <h5>{{ question.answer == user_answer ? "Bravo" : "Dommage" }} c'est</h5>
      <h3>{{ question.answer ? "Vrai" : "Faux" }} !</h3>
      <img
        src="@/assets/icons/thumb.svg"
        alt="Icone de pouce en l'air"
        width="39.89"
        height="38.67"
      />
    </div>
    <img
      :src="'./question_images/' + question.image"
      alt="Icone pour la question"
      width="279"
      height="206"
      loading="lazy"
    />
    <p v-html="question.explain"></p>
  </div>
</template>
<script>
import TimerCountdown from "../utils/TimerCountdown.vue";
export default {
  name: "QuestionShow",
  components: {
    TimerCountdown,
  },
  props: ["question_prop", "timer_prop", "user_answer_prop"],
  data() {
    return {
      timer: this.timer_prop,
      question: this.question_prop,
      user_answer: this.user_answer_prop,
    };
  },
  methods: {
    saveUserResponse(value) {
      this.$emit("userResponse", value);
    },
  },
  watch: {
    question_prop: {
      handler(newQuestion) {
        this.question = newQuestion;
      },
      deep: true,
    },
    timer_prop: {
      handler(newTimer) {
        this.timer = newTimer;
      },
    },
    user_answer_prop: {
      handler(newUserAnswer) {
        this.user_answer = newUserAnswer;
      },
    },
  },
};
</script> 
<style scoped>
.question_show {
  margin-top: 110px;
}
.question_show h4 {
  margin: 0 5px 20px;
  max-width: 1515px;
  text-align: center;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 25px;
}
.question_show .timer_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
}
.question_show .answers {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}
.question_show .answers .answer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  padding: 24px 30px 20px 30px;
  border-radius: 20px;
  border: 0;
  box-shadow: 2px 6px 8px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  text-transform: uppercase;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 38px;
}
.question_show .answers .answer img {
  max-width: 100%;
  height: auto;
}
.question_show .answers .answer.true {
  background-color: #9ec759;
}
.question_show .answers .answer.true img {
  transform: rotate(-20.04deg);
}
.question_show .answers .answer.false {
  background-color: #d60000;
}
.question_show .answers .answer.false img {
  transform: rotate(135deg);
}
.question_show .answers .answer.selected {
  box-shadow: inset 2px 6px 15px 1px rgba(0, 0, 0, 0.7);
}
.question_show .answers .answer.selected.true {
  background-color: #759f2e;
}
.question_show .answers .answer.selected.false {
  background-color: #b50202;
}

.question_result {
  margin-top: 54px;
  padding: 0 18px;
}

.question_result .answer_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.question_result .answer_title h5 {
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  /* font */
  font-family: "Quicksand";
  font-weight: 500;
  font-size: 1.5em;
  line-height: 30px;
}
.question_result .answer_title h3 {
  margin: 0 0 5px 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 10;
  /* font */
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 2.5em;
  line-height: 50px;
}
.question_result .answer_title h3::after {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 7px;
  left: 0;
  right: 0;
  width: 100%;
  height: 14px;
  border-radius: 2px;
}
.question_result .answer_title.true h3::after {
  background-color: rgba(158, 199, 89, 0.92);
}
.question_result .answer_title.false h3::after {
  background-color: #d60000;
}
.question_result .answer_title.true img {
  transform: rotate(-20.04deg);
}
.question_result .answer_title.false img {
  transform: rotate(149.95deg);
}

.question_result img {
  margin-bottom: 15px;
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

.question_result p {
  margin: 0;
  text-align: left;
  /* font */
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1em;
  line-height: 25px;
}
</style>