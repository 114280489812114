<template>
  <div class="waiting_screen_container">
    <div class="waiting_screen_title">
      <h3>Veuillez patientez</h3>
      <div class="icons">
        <img
          class="loading_style loading_delay_1"
          src="@/assets/icons/poisson.svg"
          alt="Icone de poisson"
          width="30.57"
          height="14.81"
        />
        <img
          class="loading_style loading_delay_2"
          src="@/assets/icons/pince.svg"
          alt="Icone de pince"
          width="28.76"
          height="15.8"
        />
        <img
          class="loading_style loading_delay_3"
          src="@/assets/icons/coquillage.svg"
          alt="Icone de coquillage"
          width="28.25"
          height="15.8"
        />
      </div>
    </div>
    <div class="waiting_screen_sentence">
      <p v-html="sentence"></p>
    </div>
  </div>
</template>
<script>
export default {
  name: "WaitingScreen",
  props: {
    sentence: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
<style scoped>
.waiting_screen_container {
  padding: 0 5px;
}

.waiting_screen_container .waiting_screen_title {
  margin-bottom: 60px;
}
.waiting_screen_container .waiting_screen_title h3 {
  margin-top: 0;
  text-align: center;
  margin-bottom: 15px;
  text-transform: uppercase;
  /* font */
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 1.25em;
  line-height: 25px;
}
.waiting_screen_container .waiting_screen_title .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

.waiting_screen_container .waiting_screen_sentence {
  padding: 0 2px;
  text-align: center;
  /* font */
  font-family: "Quicksand";
  font-weight: 600;
  font-size: 1em;
  line-height: 24px;
}
.waiting_screen_container .waiting_screen_sentence p {
  margin: 0;
}

.loading_style {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-name: v-syncStretchDelay;
}
.loading_delay_1 {
  animation-delay: 0s;
}
.loading_delay_2 {
  animation-delay: 0.2s;
}
.loading_delay_3 {
  animation-delay: 0.4s;
}

/* Loading animations */
@-webkit-keyframes v-syncStretchDelay {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes v-syncStretchDelay {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>