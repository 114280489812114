<template>
  <div class="question_count">{{ count }}/{{ total }}</div>
</template>
<script>
export default {
  name: "QuestionCount",
  props: ["question_count", "total_question_count"],
  data() {
    return {
      count: this.question_count,
      total: this.total_question_count,
    };
  },
  watch: {
    question_count: {
      handler(newCount) {
        this.count = newCount;
      },
    },
    total_question_count: {
      handler(newTotalCount) {
        this.total = newTotalCount;
      },
    },
  },
};
</script>
<style scoped>
.question_count {
  box-sizing: border-box;
  width: 68.17px;
  height: 68.17px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(15deg);
  background-color: #14599c;
  border: 5px solid #fff;
  border-radius: 50%;
  color: #fff;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3125em;
  line-height: 26px;
}
</style>