<template>
  <div class="question_error">
    <h4>
      Un quiz est toujours en cours. <br />Si vous avez rencontré une erreur,
      vous pouvez réinitialiser le jeu, <b>pour tous les utilisateurs</b>, en
      entrant le bon mot de passe ci-dessous.
    </h4>
    <div class="input_container">
      <input
        type="password"
        v-model="password_model"
        placeholder="Entrez le mot de passe"
        maxlength="20"
      />
      <p class="error-message" v-if="password_error">
        Le mot de passe pour réinitialiser le quizz est incorrect.
      </p>
    </div>
    <button type="button" @click="refreshGame">Réinitialiser le quiz</button>
  </div>
</template>
<script>
export default {
  name: "QuizzError",
  data() {
    return {
      password_model: "",
      password: "46TMAH3987GRF6C",
      password_error: false,
    };
  },
  methods: {
    refreshGame() {
      this.password_error = false;
      if (this.password_model == this.password) {
        this.$emit("refreshGame");
      } else {
        this.password_error = true;
      }
    },
  },
};
</script>
<style scoped>
.question_error {
  margin-top: 60px;
}
.question_error h4 {
  margin: 42px auto 30px;
  max-width: 900px;
  text-align: center;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  line-height: 50px;
}

.question_error .input_container {
  margin: 40px 5px 24px;
}
.question_error .input_container input {
  width: 250px;
  max-width: 100%;
  padding: 19px 30px;
  border: 0;
  background-color: #f5f5f5;
  color: #000000;
  /* font */
  font-family: "Quicksand";
  font-weight: 400;
  font-size: 0.875em;
  line-height: 100%;
}
.question_error .input_container input:focus,
.question_error .input_container input:focus-visible {
  outline: none;
}

.question_error .input_container .error-message {
  margin: 10px 0 0 0;
  color: #e6b319;
  /*font */
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 1em;
  line-height: 120%;
}
.question_error button {
  cursor: pointer;
  padding: 14.5px 18px 14.5px 22px;
  border: none;
  color: #fff;
  background-color: #e6b319;
  border-radius: 16px;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125em;
  line-height: 28px;
}
</style>