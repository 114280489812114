import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

createApp(App).use(router).use(LottieAnimation).mount('#app');

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});