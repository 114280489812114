<template>
  <div class="base-timer" :style="cssVars">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <path
          :stroke-dasharray="circleDasharray"
          :class="remainingPathColor"
          class="base-timer__path-remaining"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>
    <span class="base-timer__label">
      <!-- Remaining time label -->
      {{ time_left }}
    </span>
  </div>
</template>
<script>
export default {
  name: "TimerCountdown",
  props: {
    timer_prop: {
      type: [Number, String],
      required: true,
    },
    timer_width: {
      type: Number,
      default: 77,
    },
    timer_height: {
      type: Number,
      default: 77,
    },
    timer_stroke_width: {
      type: Number,
      default: 4,
    },
    label_font_size: {
      type: Number,
      default: 30,
    },
    label_line_height: {
      type: Number,
      default: 36,
    },
  },
  data() {
    return {
      time_start: this.timer_prop,
      time_left: this.timer_prop,
      alertThreshold: this.timer_prop / 4,
      warningThreshold: this.timer_prop / 2,
    };
  },
  computed: {
    cssVars() {
      return {
        "--timer-width": this.timer_width + "px",
        "--timer-height": this.timer_height + "px",
        "--timer-stroke-width": this.timer_stroke_width + "px",
        "--label-font-size": this.label_font_size + "px",
        "--label-line-height": this.label_line_height + "px",
      };
    },
    // Update the dasharray value as time passes, starting with 283
    circleDasharray() {
      let ray = this.time_left > 0 ? (this.timeFraction * 283).toFixed(0) : 0;
      return `${ray} 283`;
    },
    timeFraction() {
      // Divides time left by the defined time limit.
      const rawTimeFraction = this.time_left / this.time_start;

      return rawTimeFraction - (1 / this.time_start) * (1 - rawTimeFraction);
    },
    colorCodes() {
      return {
        info: {
          color: "green",
        },
        warning: {
          color: "orange",
          threshold: this.warningThreshold,
        },
        alert: {
          color: "red",
          threshold: this.alertThreshold,
        },
      };
    },
    remainingPathColor() {
      const { alert, warning, info } = this.colorCodes;
      if (this.time_left <= alert.threshold) {
        return alert.color;
      } else if (this.time_left <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },
  watch: {
    timer_prop: {
      handler(newTime) {
        this.time_left = newTime;
      },
    },
  },
};
</script>
<style scoped lang="scss">
/* Sets the containers height and width */
.base-timer {
  position: relative;
  width: var(--timer-width);
  height: var(--timer-height);
  /* Removes SVG styling that would hide the time label */
  &__circle {
    fill: #d9d9d9;
    // fill: none;
    stroke: none;
  }
  &__path-remaining {
    /* Just as thick as the original ring */
    stroke-width: var(--timer-stroke-width);
    /* Rounds the line endings to create a seamless circle */
    stroke-linecap: round;
    /* Makes sure the animation starts at the top of the circle */
    transform: rotate(90deg);
    transform-origin: center;
    /* One second aligns with the speed of the countdown timer */
    transition: 1s linear all, 0s linear color;
    /* Allows the ring to change color when the color value updates */
    fill-rule: nonzero;
    stroke: currentColor;
    &.green {
      color: #008a37;
    }
    &.orange {
      color: #e6b319;
    }
    &.red {
      color: #d60000;
    }
  }
  &__svg {
    /* Flips the svg and makes the animation to move left-to-right */
    transform: scaleX(-1);
  }
  &__label {
    position: absolute;

    /* Size should match the parent container */
    width: var(--timer-width);
    height: var(--timer-height);
    /* Keep the label aligned to the top */
    top: 0;
    /* Create a flexible box that centers content vertically and horizontally */
    display: flex;
    align-items: center;
    justify-content: center;
    /* Sort of an arbitrary number; adjust to your liking */
    color: #000000;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
    text-align: center;
  }
}
</style>