<template>
  <div class="question_count">{{ count }}/{{ total }}</div>
</template>
<script>
export default {
  name: "QuestionCount",
  props: ["question_count", "total_question_count"],
  data() {
    return {
      count: this.question_count,
      total: this.total_question_count,
    };
  },
  watch: {
    question_count: {
      handler(newCount) {
        this.count = newCount;
      },
    },
    total_question_count: {
      handler(newTotalCount) {
        this.total = newTotalCount;
      },
    },
  },
};
</script>
<style scoped>
.question_count {
  box-sizing: border-box;
  width: 131px;
  height: 131px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 65px;
  right: 80px;
  transform: rotate(15deg);
  background-color: #14599c;
  border: 12px solid #fff;
  border-radius: 50%;
  color: #fff;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 2.125em;
  line-height: 42px;
}
</style>