<template>
  <div>
    <div v-if="session_started">
      <template v-if="!game_started">
        <WaitingScreen :user_list="users" @startGame="startAGame" />
      </template>
      <template v-else>
        <div v-if="show_result">
          <UsersRank
            :session_rank="session_leaderboard"
            :general_rank="general_leaderboard"
            @replayGame="replayAGame"
          />
        </div>
        <template v-else>
          <div v-if="on_question_launch" class="question_launch">
            <QuizzTitle />
            <div class="timer_wrapper">
              <TimerCountdown
                :timer_prop="timer"
                :timer_width="125"
                :timer_height="125"
                :timer_stroke_width="5"
                :label_font_size="40"
                :label_line_height="46"
              />
            </div>
          </div>
          <div v-else>
            <QuestionShow
              :question_prop="question"
              :timer_prop="timer"
              :question_count_prop="question_count"
              :quizz_ended_props="quizz_ended"
              :top_users="users_top_three"
              @launchNextQuestion="launchNextQuestion"
              @showResults="showResults"
              @refreshGame="refreshAGame"
            />
          </div>
          <QuestionCount
            :question_count="question_count"
            :total_question_count="total_question_count"
          />
        </template>
      </template>
      <img
        v-if="
          timer <= 0 &&
          !show_result &&
          !on_question_launch &&
          question &&
          question.answer == true
        "
        class="background_gradient"
        src="@/assets/backgrounds/green.png"
      />
      <img
        v-else-if="
          timer <= 0 &&
          !show_result &&
          !on_question_launch &&
          question &&
          question.answer == false
        "
        class="background_gradient"
        src="@/assets/backgrounds/red.png"
      />
      <img
        v-else
        class="background_gradient"
        src="@/assets/backgrounds/default.png"
      />
    </div>
    <div v-else>
      <HomeScreen @startSession="startASession" />
    </div>
    <AdminFooter />
  </div>
</template>
<script>
//importing the socket.io we installed
import io from "socket.io-client";
import HomeScreen from "../components/admins/HomeScreen.vue";
import WaitingScreen from "../components/admins/WaitingScreen.vue";
import QuizzTitle from "../components/utils/QuizzTitle.vue";
import QuestionCount from "../components/admins/QuestionCount.vue";
import TimerCountdown from "../components/utils/TimerCountdown.vue";
import QuestionShow from "../components/admins/QuestionShow.vue";
import UsersRank from "../components/admins/UsersRank.vue";
import AdminFooter from "../components/admins/AdminFooter.vue";
export default {
  name: "AdminsPresent",
  components: {
    HomeScreen,
    WaitingScreen,
    QuizzTitle,
    QuestionCount,
    TimerCountdown,
    QuestionShow,
    UsersRank,
    AdminFooter,
  },
  data() {
    return {
      socket: {},
      session_started: false,
      users: [],
      game_started: false,
      timer: 0,
      timer_interval: null,
      total_question_count: 0,
      question_count: 0,
      on_question_launch: false,
      question: {
        question: "",
        answer: null,
        explain: "",
        image: "",
      },
      users_top_three: [],
      quizz_ended: false,
      show_result: false,
      session_leaderboard: [],
      general_leaderboard: [],
    };
  },
  //the created lifecycle hook
  created() {
    //connecting to our host
    this.socket = io(process.env.VUE_APP_SOCKET_URL);
  },
  //the mounted lifecycle hook
  mounted() {
    // Refresh view when server restart
    this.socket.on("refresh-all", () => {
      this.reset();
    });
    //Get total question foreach sessions
    this.socket.on("total-question-count", (data) => {
      this.total_question_count = data;
    });
    //Get users list updated
    this.socket.on("users-list", (data) => {
      this.users = data;
    });
    //Check if a new session started
    this.socket.on("has_session_started", (data) => {
      this.session_started = data;
    });
    //Check if a game as started
    this.socket.on("current_session_started", (data) => {
      this.game_started = data;
    });

    this.socket.on("launch_new_question", (data) => {
      clearInterval(this.timer_interval);
      this.game_started = true;
      this.users_top_three = [];
      this.question_count++;
      this.on_question_launch = true;
      let self = this;
      this.timer_interval = setInterval(() => {
        self.timer -= 1;
      }, 1000);
      this.timer = data;
    });

    this.socket.on("current_question_started", (data) => {
      clearInterval(this.timer_interval);
      let self = this;
      this.timer_interval = setInterval(() => {
        self.timer -= 1;
      }, 1000);
      this.on_question_launch = false;
      this.timer = data.timer;
      this.question = data;
    });

    this.socket.on("question_ended", () => {
      this.timer = 0;
      clearInterval(this.timer_interval);
    });

    this.socket.on("question-scores", (data) => {
      this.users_top_three = data;
    });

    this.socket.on("quizz-ended", (data) => {
      this.quizz_ended = true;
      this.general_leaderboard = data.general_leaderboard;
      this.session_leaderboard = data.session_leaderboard;
    });

    this.socket.on("show_results", () => {
      this.show_result = true;
    });
  },
  //creating a method to emit the directions to the server side
  methods: {
    startASession() {
      this.session_started = true;
      this.socket.emit("start_new_session");
    },
    startAGame() {
      if (this.users.length > 0) {
        this.socket.emit("start_new_game");
      }
    },
    launchNextQuestion() {
      this.socket.emit("launch_next_question");
    },
    showResults() {
      this.socket.emit("get_results");
    },
    replayAGame() {
      this.socket.emit("replay_a_game");
      this.reset();
    },
    refreshAGame() {
      this.socket.emit("refresh_a_game");
      this.reset();
    },
    reset() {
      this.session_started = false;
      this.users = [];
      this.game_started = false;
      this.timer = 0;
      clearInterval(this.timer_interval);
      this.question = {
        question: "",
        answer: null,
        explain: "",
        image: "",
      };
      this.total_question_count = 0;
      this.question_count = 0;
      this.on_question_launch = false;
      this.quizz_ended = false;
      this.users_top_three = [];
      this.show_result = false;
      this.session_leaderboard = [];
      this.general_leaderboard = [];
    },
  },
};
</script>
<style scoped>
.background_gradient {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.question_launch {
  margin-top: 80px;
}

.timer_wrapper {
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>